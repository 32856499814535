import React, { Component } from "react";
import { validated } from "react-custom-validation";
import { isEmpty } from "../Helpers/SettingHelper";

function validationConfig(props) {
  const { otp } = props.fields;

  return {
    fields: ["otp"],

    validations: {
      otp: [[isEmpty, otp]],
    },
  };
}

class Form extends Component {
  render() {
    const { fields, onChange, onValid, onInvalid, $field, $validation } =
      this.props;
    let errMsgOTP = "";

    if ($validation.otp.error.reason !== undefined) {
      errMsgOTP = $validation.otp.show && $validation.otp.error.reason;
    }

    return (
      <div className="mb-3">
        <div
          className={
            errMsgOTP !== "" && errMsgOTP !== false
              ? "form-floating form-floating-outline mb-3 fv-plugins-bootstrap5-row-invalid"
              : "form-floating form-floating-outline mb-3"
          }
        >
          <input
            type="text"
            className={
              errMsgOTP !== "" && errMsgOTP !== false
                ? "form-control is-invalid"
                : "form-control"
            }
            id="otp"
            name="otp"
            placeholder="Enter your OTP"
            value={fields.otp}
            {...$field("otp", (e) => onChange("otp", e.target.value))}
          />
          <label htmlFor="email">OTP</label>
          {errMsgOTP !== "" && errMsgOTP !== false && (
            <div className="fv-plugins-message-container invalid-feedback">
              <div data-field="email-otp" data-validator="notEmpty">
                {errMsgOTP}
              </div>
            </div>
          )}
        </div>
        <div className="mb-3">
          <button
            type="button"
            className="btn btn-primary d-grid w-100 do_login"
            onClick={(e) => {
              e.preventDefault();
              this.props.$submit(onValid, onInvalid);
            }}
          >
            Verify OTP
          </button>
        </div>
      </div>
    );
  }
}
export default Form = validated(validationConfig)(Form);
