import React from "react";
/* import { render } from "react-dom"; */
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { getStore } from "./store";

import "./assets/vendor/fonts/materialdesignicons.css";
import "./assets/vendor/fonts/fontawesome.css";
import "./assets/vendor/css/rtl/core.css";
import "./assets/vendor/css/rtl/theme-default.css";
import "./assets/css/demo.css";
import "./assets/vendor/libs/perfect-scrollbar/perfect-scrollbar.css";
import "./assets/vendor/libs/node-waves/node-waves.css";
import "./assets/vendor/libs/typeahead-js/typeahead.css";
import "./assets/vendor/libs/formvalidation/dist/css/formValidation.min.css";
import "./assets/vendor/css/pages/page-auth.css";
import "./assets/vendor/libs/toastr/toastr.css";
import "./assets/vendor/libs/dropzone/dropzone.css";

import "./common/css/slick.css";
import "./common/css/custom.css";

import Login from "./components/Auth/Login";
import Productslist from "./components/Products/List";
import Productslist1 from "./components/Products/List1";
import CommissionReport from "./components/Reports/CommissionReport";
import CommissionHistoryReport from "./components/Reports/CommissionHistoryReport";
import Payout from "./components/Reports/Payout";

import Refpage from "./components/Layout/Refpage";
import Logout from "./components/Layout/Logout";
import Page404 from "./Page404";

const store = getStore();
const container = document.getElementById("root");
const root = createRoot(container);
root.render(
  <Provider store={store}>
    <Router>
      <Switch>
        <Route exact path="/" component={Login} />
        <Route exact path="/brands" component={Productslist} />
        <Route exact path="/vouchers1" component={Productslist1} />
        <Route
          exact
          path="/earnings/:CompanyID"
          component={CommissionHistoryReport}
        />
        <Route exact path="/earnings" component={CommissionReport} />
        <Route exact path="/payout" component={Payout} />

        {/*  Client Panel Start*/}

        <Route path={"/refpage/:slugtext"} component={Refpage} />
        <Route exact path="/logout" component={Logout} />

        <Route component={Page404} />
      </Switch>
    </Router>
  </Provider>
);
