/* Live */
import cookie from "react-cookies";
export const apiUrl = "https://walletapi.goepicpay.com/api/";

//export const baseUrl = "http://localhost:3000/";
export const baseUrl = "https://influencer.goepicpay.com/";
export const forntEndUrl = "https://wallet.goepicpay.com/";

export const adminlimit = "10";
var accesstoken = {
  Authorization: cookie.load("accessToken"),
};

export const headerConfig = {
  headers: accesstoken,
};
