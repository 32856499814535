import React, { Component } from "react";
import { validated } from "react-custom-validation";
import { isEmpty } from "../Helpers/SettingHelper";

function validationConfig(props) {
  const { username, password } = props.fields;

  return {
    fields: ["username", "password"],

    validations: {
      username: [[isEmpty, username]],
      password: [[isEmpty, password]],
    },
  };
}

class Form extends Component {
  constructor(props) {
    super(props);
    this.state = { pass_input_type: "password" };
  }

  changePassInputtype() {
    let passIntTyp = this.state.pass_input_type;
    passIntTyp = passIntTyp == "password" ? "text" : "password";
    this.setState({ pass_input_type: passIntTyp });
  }

  render() {
    const { fields, onChange, onValid, onInvalid, $field, $validation } =
      this.props;
    let errMsgUsername = "";
    let errMsgPassword = "";

    if ($validation.username.error.reason !== undefined) {
      errMsgUsername = $validation.username.show && (
        <span className="error">{$validation.username.error.reason}</span>
      );
    }
    if ($validation.password.error.reason !== undefined) {
      errMsgPassword = $validation.password.show && (
        <span className="error">{$validation.password.error.reason}</span>
      );
    }

    return (
      <div className="mb-3">
        <div
          className={
            errMsgUsername !== "" && errMsgUsername !== false
              ? "form-floating form-floating-outline mb-3 fv-plugins-bootstrap5-row-invalid"
              : "form-floating form-floating-outline mb-3"
          }
        >
          <input
            type="text"
            className={
              errMsgUsername !== "" && errMsgUsername !== false
                ? "form-control is-invalid"
                : "form-control"
            }
            id="username"
            name="username"
            placeholder="Enter your username"
            value={fields.username}
            {...$field("username", (e) => onChange("username", e.target.value))}
          />
          <label htmlFor="email">Username</label>
          {errMsgUsername !== "" && errMsgUsername !== false && (
            <div className="fv-plugins-message-container invalid-feedback">
              <div data-field="email-username" data-validator="notEmpty">
                Please enter username
              </div>
            </div>
          )}
        </div>
        <div className="mb-3">
          <div className="form-password-toggle">
            <div className="input-group input-group-merge">
              <div
                className={
                  errMsgPassword !== "" && errMsgPassword !== false
                    ? "form-floating form-floating-outline fv-plugins-bootstrap5-row-invalid"
                    : "form-floating form-floating-outline"
                }
              >
                <input
                  type={this.state.pass_input_type}
                  id="password"
                  className={
                    errMsgPassword !== "" && errMsgPassword !== false
                      ? "form-control is-invalid"
                      : "form-control"
                  }
                  name="password"
                  placeholder="&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;"
                  aria-describedby="password"
                  value={fields.password}
                  {...$field("password", (e) =>
                    onChange("password", e.target.value)
                  )}
                />
                <label htmlFor="password">Password</label>
                {errMsgPassword !== "" && errMsgPassword !== false && (
                  <div className="fv-plugins-message-container invalid-feedback">
                    <div data-field="email-username" data-validator="notEmpty">
                      Please enter password
                    </div>
                  </div>
                )}
              </div>
              <span
                className="input-group-text cursor-pointer"
                style={{ height: "49px" }}
                onClick={this.changePassInputtype.bind(this)}
              >
                {this.state.pass_input_type == "password" ? (
                  <i className="mdi mdi-eye-off-outline"></i>
                ) : (
                  <i className="mdi mdi-eye-outline"></i>
                )}
              </span>
            </div>
          </div>
        </div>
        <div className="mb-3 d-flex justify-content-between">
          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              id="remember-me"
            />
            <label className="form-check-label" htmlFor="remember-me">
              {" "}
              Remember Me{" "}
            </label>
          </div>
          <a href="auth-forgot-password-basic.html" className="float-end mb-1">
            <span>Forgot Password?</span>
          </a>
        </div>
        <div className="mb-3">
          <button
            type="button"
            className="btn btn-primary d-grid w-100 do_login"
            onClick={(e) => {
              e.preventDefault();
              this.props.$submit(onValid, onInvalid);
            }}
          >
            Sign in
          </button>
        </div>
      </div>
    );
  }
}
export default Form = validated(validationConfig)(Form);
