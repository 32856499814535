/* eslint-disable */
import React, { Component } from "react";
import cookie from "react-cookies";
import axios from "axios";
import update from "immutability-helper";
import { apiUrl, baseUrl } from "../Helpers/Config";
import Signup from "./Signup";
import Signin from "./Signin";
import OTPVerify from "./OTPVerify";
import { showLoader, hideLoader, showAlert } from "../Helpers/SettingHelper";
var qs = require("qs");
var Parser = require("html-react-parser");
var base64 = require("base-64");
class Login extends Component {
  constructor(props) {
    super(props);
    var LoginType =
      this.props.match.params !== "" &&
      typeof this.props.match.params !== undefined &&
      typeof this.props.match.params !== "undefined"
        ? this.props.match.params.LoginType
        : "";
    var clientID =
      this.props.match.params !== "" &&
      typeof this.props.match.params !== undefined &&
      typeof this.props.match.params !== "undefined"
        ? this.props.match.params.clientID
        : "";
    LoginType = LoginType != undefined ? LoginType : "";
    clientID = clientID != undefined ? clientID : "";
    this.state = {
      fields: {
        username: "",
        password: "",
      },
      signupfields: {
        name: "",
        email: "",
        password: "",
        phoneNumber: "",
      },
      otpfields: {
        otp: "",
      },
      error_msg: "",
      login_type: LoginType,
      client_id: clientID,
      formType: "login",
    };

    if (LoginType != "" && LoginType != "masteradmin") {
      location.href = baseUrl + "clientpanel/login";
    }
  }

  /* signin - start*/
  fieldChange = (field, value) => {
    this.setState(update(this.state, { fields: { [field]: { $set: value } } }));
  };

  handleSignin = () => {
    const formPayload = this.state.fields;
    this.setState({ error_msg: "" });
    var postObject = {
      email: formPayload.username,
      password: formPayload.password,
    };
    showLoader("do_login", "class");

    axios
      .post(apiUrl + "clientpanel/influencer/login", qs.stringify(postObject))
      .then((res) => {
        hideLoader("do_login", "class");
        if (res.data.status === "success") {
          var ResulSet = res.data.result;
          cookie.save("influencerID", base64.encode(ResulSet.customer_id), {
            path: "/",
          });
          cookie.save("influencerName", ResulSet.customer_first_name, {
            path: "/",
          });
          cookie.save("accessToken", res.data.token, {
            path: "/",
          });
          location.href = baseUrl + "brands";
        } else {
          var errorMsg =
            res.data.message !== "" ? Parser(res.data.message) : "";
          showAlert("Error", errorMsg, "error");
        }
      });
  };
  /* signin - end*/

  /* signin - start*/
  fieldChangeSignup = (field, value) => {
    this.setState(
      update(this.state, { signupfields: { [field]: { $set: value } } })
    );
  };

  handleSignup = () => {
    const formPayload = this.state.signupfields;
    this.setState({ error_msg: "" });
    var postObject = {
      name: formPayload.name,
      phoneNumber: formPayload.phoneNumber,
      email: formPayload.email,
      password: formPayload.password,
    };
    showLoader("do_login", "class");

    axios
      .post(apiUrl + "clientpanel/influencer/sendOTP", qs.stringify(postObject))
      .then((res) => {
        hideLoader("do_login", "class");
        if (res.data.status === "success") {
          this.setState({ formType: "otpverify" });
        } else {
          var errorMsg =
            res.data.message !== "" ? Parser(res.data.message) : "";
          showAlert("Error", errorMsg, "error");
        }
      });
  };
  /* signin - end*/

  /* signin - start*/
  fieldChangeOTP = (field, value) => {
    this.setState(
      update(this.state, { otpfields: { [field]: { $set: value } } })
    );
  };

  handleVerifyOTP = () => {
    const formPayload = this.state.signupfields;
    const formotpPayload = this.state.otpfields;
    this.setState({ error_msg: "" });
    var postObject = {
      email: formPayload.email,
      otp: formotpPayload.otp,
    };
    showLoader("do_login", "class");

    axios
      .post(
        apiUrl + "clientpanel/influencer/verifyOTP",
        qs.stringify(postObject)
      )
      .then((res) => {
        if (res.data.status === "success") {
          this.signup();
        } else {
          var errorMsg =
            res.data.message !== "" ? Parser(res.data.message) : "";
          showAlert("Error", errorMsg, "error");
          hideLoader("do_login", "class");
        }
      });
  };

  signup() {
    const formPayload = this.state.signupfields;
    this.setState({ error_msg: "" });
    var postObject = {
      customer_name: formPayload.name,
      customer_phone: formPayload.phoneNumber,
      customer_email: formPayload.email,
      customer_password: formPayload.password,
      customerType: "Influencer",
    };
    showLoader("do_login", "class");

    axios
      .post(apiUrl + "customer/registration", qs.stringify(postObject))
      .then((res) => {
        hideLoader("do_login", "class");
        if (res.data.status === "ok") {
          var errorMsg =
            res.data.message !== "" ? Parser(res.data.message) : "";
          showAlert("Success", errorMsg, "success");
          this.setState({ formType: "login" });
        } else {
          var errorMsg =
            res.data.message !== "" ? Parser(res.data.message) : "";
          showAlert("Error", errorMsg, "error");
        }
      });
  }

  /* signin - end*/

  setformType(formType, event) {
    event.preventDefault();
    this.setState({ formType: formType });
  }

  render() {
    return (
      <div className="position-relative">
        <div className="authentication-wrapper authentication-basic container-p-y">
          <div className="authentication-inner py-4">
            <div className="card p-2">
              <div className="app-brand justify-content-center mt-5">
                <a href="index.html" className="app-brand-link gap-2">
                  <span className="app-brand-logo demo">
                    <span>
                      <img src={"/epicpay.png"} alt="GoepicPay" />
                    </span>
                  </span>
                </a>
              </div>

              <div className="card-body mt-2">
                <h4 className="mb-2 fw-semibold">
                  Welcome to GoepicPay Influencer Panel! 👋
                </h4>
                {this.state.formType === "login" && (
                  <>
                    <p className="mb-4">
                      Please sign-in to your account and start the adventure
                    </p>
                    <Signin
                      {...this.props}
                      fields={this.state.fields}
                      onChange={this.fieldChange}
                      onValid={this.handleSignin}
                      error_msg={this.state.error_msg}
                      onInvalid={() => console.log("Form invalid!")}
                    />
                    <p className="text-center">
                      <span>New on our platform?</span>&nbsp;
                      <a
                        href="#"
                        onClick={this.setformType.bind(this, "signup")}
                      >
                        <span>Create an account</span>
                      </a>
                    </p>
                  </>
                )}
                {this.state.formType === "signup" && (
                  <>
                    <p className="mb-4">
                      Please sign-up to your account and start the adventure
                    </p>
                    <Signup
                      {...this.props}
                      fields={this.state.signupfields}
                      onChange={this.fieldChangeSignup}
                      onValid={this.handleSignup}
                      error_msg={this.state.error_msg}
                      onInvalid={() => console.log("Form invalid!")}
                    />
                    <p className="text-center">
                      <span>Already have an account?</span>&nbsp;
                      <a
                        href="#"
                        onClick={this.setformType.bind(this, "login")}
                      >
                        <span>Sign in instead</span>
                      </a>
                    </p>
                  </>
                )}
                {this.state.formType === "otpverify" && (
                  <>
                    <p className="mb-4">
                      Please OTP to your account and start the adventure
                    </p>
                    <OTPVerify
                      {...this.props}
                      fields={this.state.otpfields}
                      onChange={this.fieldChangeOTP}
                      onValid={this.handleVerifyOTP}
                      error_msg={this.state.error_msg}
                      onInvalid={() => console.log("Form invalid!")}
                    />
                    <p className="text-center">
                      <a
                        href="#"
                        onClick={this.setformType.bind(this, "signup")}
                      >
                        <span>Back to Sign Up</span>
                      </a>
                    </p>
                  </>
                )}
              </div>
            </div>
            <img
              alt="mask"
              src="../../assets/img/illustrations/auth-basic-login-mask-light.png"
              className="authentication-image d-none d-lg-block"
              data-app-light-img="illustrations/auth-basic-login-mask-light.png"
              data-app-dark-img="illustrations/auth-basic-login-mask-dark.png"
            />
          </div>
        </div>
      </div>
    );
  }
}

export default Login;
