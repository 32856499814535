import React, { Component } from "react";
import cookie from "react-cookies";

class Logout extends Component {
  constructor(props) {
    super(props);
  }
  componentWillMount() {
    cookie.remove("influencerName", { path: "/" });
    cookie.remove("influencerID", { path: "/" });
    cookie.remove("accessToken", { path: "/" });

    this.props.history.push("/");
  }
  render() {
    return <h1 className="loading-text">Logging out...</h1>;
  }
}

export default Logout;
