import React, { Component } from "react";
import { validated } from "react-custom-validation";
import { isEmpty, isEmail, isPhone } from "../Helpers/SettingHelper";

function validationConfig(props) {
  const { name, email, phoneNumber, password } = props.fields;

  return {
    fields: ["name", "email", "phoneNumber", "password"],

    validations: {
      name: [[isEmpty, name]],
      email: [
        [isEmpty, email],
        [isEmail, email],
      ],
      password: [[isEmpty, password]],
      phoneNumber: [
        [isEmpty, phoneNumber],
        [isPhone, phoneNumber],
      ],
    },
  };
}

class Form extends Component {
  constructor(props) {
    super(props);
    this.state = { pass_input_type: "password" };
  }

  changePassInputtype() {
    let passIntTyp = this.state.pass_input_type;
    passIntTyp = passIntTyp === "password" ? "text" : "password";
    this.setState({ pass_input_type: passIntTyp });
  }

  render() {
    const { fields, onChange, onValid, onInvalid, $field, $validation } =
      this.props;
    let errMsgName = "";
    let errMsgEmail = "";
    let errMsgPhoneNumber = "";
    let errMsgPassword = "";

    if ($validation.name.error.reason !== undefined) {
      errMsgName = $validation.name.show ? $validation.name.error.reason : "";
    }
    if ($validation.email.error.reason !== undefined) {
      errMsgEmail = $validation.email.show
        ? $validation.email.error.reason
        : "";
    }
    if ($validation.phoneNumber.error.reason !== undefined) {
      errMsgPhoneNumber =
        $validation.phoneNumber.show && $validation.phoneNumber.error.reason;
    }
    if ($validation.password.error.reason !== undefined) {
      errMsgPassword =
        $validation.password.show && $validation.password.error.reason;
    }
    console.log(errMsgName, "errMsgNameerrMsgNameerrMsgName");
    return (
      <div className="mb-3">
        <div
          className={
            errMsgName !== "" && errMsgName !== false
              ? "form-floating form-floating-outline mb-3 fv-plugins-bootstrap5-row-invalid"
              : "form-floating form-floating-outline mb-3"
          }
        >
          <input
            type="text"
            className={
              errMsgName !== "" && errMsgName !== false
                ? "form-control is-invalid"
                : "form-control"
            }
            id="name"
            name="name"
            placeholder="Enter your name"
            value={fields.name}
            {...$field("name", (e) => onChange("name", e.target.value))}
          />
          <label htmlFor="email">Name</label>
          {errMsgName !== "" && errMsgName !== false && (
            <div className="fv-plugins-message-container invalid-feedback">
              <div data-field="email-name" data-validator="notEmpty">
                {errMsgName}
              </div>
            </div>
          )}
        </div>
        <div
          className={
            errMsgEmail !== "" && errMsgEmail !== false
              ? "form-floating form-floating-outline mb-3 fv-plugins-bootstrap5-row-invalid"
              : "form-floating form-floating-outline mb-3"
          }
        >
          <input
            type="text"
            className={
              errMsgEmail !== "" && errMsgEmail !== false
                ? "form-control is-invalid"
                : "form-control"
            }
            id="email"
            name="email"
            placeholder="Enter your email"
            value={fields.email}
            {...$field("email", (e) => onChange("email", e.target.value))}
          />
          <label htmlFor="email">Eamil</label>
          {errMsgEmail !== "" && errMsgEmail !== false && (
            <div className="fv-plugins-message-container invalid-feedback">
              <div data-field="email-email" data-validator="notEmpty">
                {errMsgEmail}
              </div>
            </div>
          )}
        </div>
        <div className="mb-3">
          <div className="form-password-toggle">
            <div className="input-group input-group-merge">
              <div
                className={
                  errMsgPassword !== "" && errMsgPassword !== false
                    ? "form-floating form-floating-outline fv-plugins-bootstrap5-row-invalid"
                    : "form-floating form-floating-outline"
                }
              >
                <input
                  type={this.state.pass_input_type}
                  id="password"
                  className={
                    errMsgPassword !== "" && errMsgPassword !== false
                      ? "form-control is-invalid"
                      : "form-control"
                  }
                  name="password"
                  placeholder="&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;"
                  aria-describedby="password"
                  value={fields.password}
                  {...$field("password", (e) =>
                    onChange("password", e.target.value)
                  )}
                />
                <label htmlFor="password">Password</label>
                {errMsgPassword !== "" && errMsgPassword !== false && (
                  <div className="fv-plugins-message-container invalid-feedback">
                    <div data-field="email-username" data-validator="notEmpty">
                      Please enter password
                    </div>
                  </div>
                )}
              </div>
              <span
                className="input-group-text cursor-pointer"
                style={{ height: "49px" }}
                onClick={this.changePassInputtype.bind(this)}
              >
                {this.state.pass_input_type == "password" ? (
                  <i className="mdi mdi-eye-off-outline"></i>
                ) : (
                  <i className="mdi mdi-eye-outline"></i>
                )}
              </span>
            </div>
          </div>
        </div>
        <div
          className={
            errMsgPhoneNumber !== "" && errMsgPhoneNumber !== false
              ? "form-floating form-floating-outline mb-3 fv-plugins-bootstrap5-row-invalid"
              : "form-floating form-floating-outline mb-3"
          }
        >
          <input
            type="text"
            className={
              errMsgPhoneNumber !== "" && errMsgPhoneNumber !== false
                ? "form-control is-invalid"
                : "form-control"
            }
            id="phoneNumber"
            name="phoneNumber"
            placeholder="Enter your Phone Number"
            value={fields.phoneNumber}
            {...$field("phoneNumber", (e) =>
              onChange("phoneNumber", e.target.value)
            )}
            maxLength={8}
          />
          <label htmlFor="phoneNumber">Phone Number</label>
          {errMsgPhoneNumber !== "" && errMsgPhoneNumber !== false && (
            <div className="fv-plugins-message-container invalid-feedback">
              <div data-field="email-username" data-validator="notEmpty">
                {errMsgPhoneNumber}
              </div>
            </div>
          )}
        </div>

        <div className="mb-3">
          <button
            type="button"
            className="btn btn-primary d-grid w-100 do_login"
            onClick={(e) => {
              e.preventDefault();
              this.props.$submit(onValid, onInvalid);
            }}
          >
            Sign Up
          </button>
        </div>
      </div>
    );
  }
}
export default Form = validated(validationConfig)(Form);
