/* eslint-disable */
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import cookie from "react-cookies";
import { Facebook } from "react-content-loader";
import axios from "axios";
import Select from "react-select";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  apiUrl,
  adminlimit,
  headerConfig,
  forntEndUrl,
} from "../Helpers/Config";
import { GET_LISTDATA } from "../../actions";
import {
  showStatus,
  encodeValue,
  removeItem,
  stripslashes,
  showPriceValue,
  showPercentage,
  showLoader,
  hideLoader,
  showAlert,
} from "../Helpers/SettingHelper";
import Header from "../Layout/Header";
import Topmenu from "../Layout/Topmenu";
import Footer from "../Layout/Footer";
import Pagenation from "../Layout/Pagenation";

import vouc from "../../common/images/voucher-white.svg";
import vouclogo from "../../common/images/vouc-logo.svg";

import c1 from "../../common/images/c-thumb.svg";
import c2 from "../../common/images/c-b2b.svg";
import c3 from "../../common/images/c-community.svg";
import c4 from "../../common/images/c-education.svg";
import c5 from "../../common/images/c-foods.svg";
import c6 from "../../common/images/c-health.svg";
import c7 from "../../common/images/c-house.svg";
import c8 from "../../common/images/c-legal.svg";
import c9 from "../../common/images/c-realestate.svg";
import c10 from "../../common/images/c-sportscar.svg";

import desi from "../../common/images/desigual.svg";
import globe from "../../common/images/globe.svg";
import calla from "../../common/images/phone-call.svg";
import walletlight from "../../common/images/voucher.svg";
import orange from "../../common/images/orange-shape.svg";
import voucherbg from "../../common/images/voucher-bg.png";
var qs = require("qs");
var Parser = require("html-react-parser");
var module = "clientpanel/paintbasedproducts/";
var moduleName = "Brands";
class List extends Component {
  constructor(props) {
    super(props);
    var influencerID = cookie.load("influencerID");
    this.state = {
      influencerID: influencerID,
      path: this.props.match.path,
      totalRecords: 0,
      totalPage: 0,
      currentPage: 1,
      listdata: [],
      loading: true,
      name: "",
      status: "",
      producttype: "",
      activeTap: "mybrands",
      selectedVoucher: "",
      categoryList: [],
      selectedCategory: "",
      myCampaignLoading: true,
      myBrandsList: [],
      mytotalRecords: 0,
      mytotalPage: 0,
      mycurrentPage: 1,
      appliedBrandsList: [],
      appliedtotalRecords: 0,
      appliedtotalPage: 0,
      appliedcurrentPage: 1,

      featuredBrandsList: [],
      featuredtotalRecords: 0,
      featuredtotalPage: 0,
      featuredcurrentPage: 1,
      featuredBrandsLoading: true,
    };
    this.handleChangeText = this.handleChangeText.bind(this);
  }
  componentDidMount() {
    this.loadList(1);
    this.laodCategory();
    this.loadMyBrands(1);
    this.loadFeatured(1);
    this.loadAppliedBrands(1);
    var $this = this;
    $("body").on("click", ".continuebuyVoucher", function () {
      $this.contBuyVoucher();
    });
    $("body").on("click", "#applyRefer", function () {
      $this.applyRefer();
    });
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.listdata !== this.state.listdata) {
      this.setState({
        listdata: nextProps.listdata,
        loading: false,
        totalRecords: nextProps.totalRecords,
        totalPage: nextProps.totalPages,
      });
    }
  }
  laodCategory() {
    var urlShringTxt =
      apiUrl + module + "category?influencerID=" + this.state.influencerID;
    axios.get(urlShringTxt, headerConfig).then((res) => {
      if (res.data.status === "ok") {
        this.setState({ categoryList: res.data.result });
      }
    });
  }

  sateValChange = (field, value) => {
    if (field === "page") {
      this.setState(
        {
          loading: true,
          currentPage: value,
        },
        function () {
          this.loadList(value);
        }
      );
    }
  };
  sateValChange1 = (field, value) => {
    if (field === "page") {
      this.setState(
        {
          myCampaignLoading: true,
          mycurrentPage: value,
        },
        function () {
          this.loadList(value);
        }
      );
    }
  };
  sateValChange2 = (field, value) => {
    if (field === "page") {
      this.setState(
        {
          appliedCampaignLoading: true,
          appliedcurrentPage: value,
        },
        function () {
          this.loadAppliedBrands(value);
        }
      );
    }
  };
  sateValChange3 = (field, value) => {
    if (field === "page") {
      this.setState(
        {
          featuredBrandsLoading: true,
          featuredcurrentPage: value,
        },
        function () {
          this.loadFeatured(value);
        }
      );
    }
  };

  removeItem(deleteID) {
    var params = { delete_id: deleteID, influencerID: this.state.influencerID };
    var delurl = module + "delete";
    removeItem(params, delurl, "client");
  }
  handleChangeText(event) {
    var name = event.target.name;
    var value = event.target.value;
    this.setState({ [name]: value });
  }
  handleSelectChange(name, value) {
    this.setState({ [name]: value });
  }

  searchList() {
    this.setState({ loading: true }, function () {
      this.loadList(1);
    });
  }
  filtterCategory(Category) {
    this.setState({ selectedCategory: Category }, () => {
      this.loadList(1);
    });
  }
  loadList(offset) {
    var addParams = "";

    if (
      this.state.selectedCategory !== "" &&
      this.state.selectedCategory !== "all"
    ) {
      addParams += "&category=" + this.state.selectedCategory;
    }

    var params = {
      params:
        "limit=" +
        adminlimit +
        "&offset=" +
        offset +
        "&influencerID=" +
        this.state.influencerID +
        addParams,
      url: apiUrl + module + "influencerlist",
      authType: "client",
    };
    this.props.getListData(params);
  }
  resetSearch() {
    this.setState(
      {
        loading: true,
        name: "",
        producttype: "",
        status: "",
      },
      function () {
        this.loadList(1);
      }
    );
  }
  loadMyBrands(offset) {
    var urlShringTxt =
      apiUrl +
      module +
      "mycampaigns?influencerID=" +
      this.state.influencerID +
      "&limit=" +
      adminlimit +
      "&offset=" +
      offset;
    axios.get(urlShringTxt, headerConfig).then((res) => {
      this.setState({ myCampaignLoading: false });
      if (res.data.status === "ok") {
        this.setState({
          myBrandsList: res.data.result,
          mytotalRecords: res.data.totalRecords,
          mytotalPage: res.data.totalPage,
        });
      }
    });
  }

  loadFeatured(offset) {
    var urlShringTxt =
      apiUrl +
      module +
      "influencerlist?influencerID=" +
      this.state.influencerID +
      "&limit=" +
      adminlimit +
      "&offset=" +
      offset +
      "&featured=Y";
    axios.get(urlShringTxt, headerConfig).then((res) => {
      this.setState({ featuredBrandsLoading: false });
      if (res.data.status === "ok") {
        this.setState({
          featuredBrandsList: res.data.result,
          featuredtotalRecords: res.data.totalRecords,
          featuredtotalPage: res.data.totalPage,
        });
      }
    });
  }
  loadAppliedBrands(offset) {
    var urlShringTxt =
      apiUrl +
      module +
      "mycampaigns?influencerID=" +
      this.state.influencerID +
      "&limit=" +
      adminlimit +
      "&offset=" +
      offset +
      "&status=P";
    axios.get(urlShringTxt, headerConfig).then((res) => {
      this.setState({ myCampaignLoading: false });
      if (res.data.status === "ok") {
        this.setState({
          appliedBrandsList: res.data.result,
          appliedtotalRecords: res.data.totalRecords,
          appliedtotalPage: res.data.totalPage,
        });
      }
    });
  }

  referLink(itemSlug) {
    toast.success("Link Copied Success", { autoClose: 1000 });
    navigator.clipboard.writeText(
      forntEndUrl +
        "buy-vouchers/" +
        itemSlug +
        "/" +
        cookie.load("influencerID")
    );
  }
  selectTap(activeTap) {
    this.setState({ activeTap: activeTap });
  }
  selectVoucher(voucher) {
    this.setState({ selectedVoucher: voucher }, () => {
      $.magnificPopup.open({
        items: {
          src: "#confirm_popup",
        },
        type: "inline",
      });
    });
  }

  contBuyVoucher() {
    $.magnificPopup.close();
    $.magnificPopup.open({
      items: {
        src: "#continue_popup",
      },
      type: "inline",
    });
  }
  applyRefer() {
    var postObject = {
      influencerID: this.state.influencerID,
      productID: encodeValue(this.state.selectedVoucher.product_primary_id),
    };
    showLoader("applyRefer");

    axios
      .post(
        apiUrl + module + "applyRefer",
        qs.stringify(postObject),
        headerConfig
      )
      .then((res) => {
        hideLoader("applyRefer");
        if (res.data.status === "success") {
          var showtab = res.data?.showtab || "";
          if (showtab !== "") {
            this.setState({ activeTap: showtab });
          }
          if (showtab === "mybrands") {
            this.loadMyBrands(1);
          } else if (showtab === "applied") {
            this.loadAppliedBrands(1);
          }

          var message = res.data.message;
          if (showtab === "applied") {
            showAlert("Warning", message, "warning");
          } else {
            showAlert("Success", message, "success");
          }

          $.magnificPopup.close();
        } else {
          var errorMsg =
            res.data.message !== "" ? Parser(res.data.message) : "";
          showAlert("Error", errorMsg, "error");
        }
      });
  }

  render() {
    return (
      <div className="layout-wrapper layout-content-navbar">
        <div className="layout-container">
          <Header {...this.props} currentPage={"brands"} />
          <div className="layout-page">
            <Topmenu />
            <ToastContainer />
            <div className="content-wrapper">
              <div className="container-xxl flex-grow-1 container-p-y">
                <div className="row mb-3">
                  <div className="col-lg-10 col-md-6">
                    <h4 className="fw-bold">{moduleName}</h4>
                  </div>
                </div>

                <div className="card">
                  <div className="campaign-tab">
                    <div className="campaign-tab-nav">
                      <ul>
                        <li
                          className={
                            this.state.activeTap === "featured" ? "active" : ""
                          }
                        >
                          <a
                            href={void 0}
                            onClick={this.selectTap.bind(this, "featured")}
                          >
                            Featured
                          </a>
                        </li>
                        <li
                          className={
                            this.state.activeTap === "allbrands" ? "active" : ""
                          }
                        >
                          <a
                            href={void 0}
                            onClick={this.selectTap.bind(this, "allbrands")}
                          >
                            All Brands
                          </a>
                        </li>
                        <li
                          className={
                            this.state.activeTap === "mybrands" ? "active" : ""
                          }
                        >
                          <a
                            href={void 0}
                            onClick={this.selectTap.bind(this, "mybrands")}
                          >
                            My Brands
                          </a>
                        </li>
                        <li
                          className={
                            this.state.activeTap === "applied" ? "active" : ""
                          }
                        >
                          <a
                            href={void 0}
                            onClick={this.selectTap.bind(this, "applied")}
                          >
                            Applied
                          </a>
                        </li>
                      </ul>
                    </div>
                    <div className="campaign-tab-filter"></div>
                    <div className="campaign-tab-content">
                      {this.state.activeTap === "mybrands" && (
                        <div>
                          <div className="campaign-tab-content-inner tab1">
                            {this.state.myCampaignLoading === true ? (
                              <ul className="grid-tab gt-viewmore">
                                <li>
                                  <Facebook
                                    backgroundColor={"#c7c7c7"}
                                    foregroundColor={"#c7c7c7"}
                                  />
                                </li>
                                <li>
                                  <Facebook
                                    backgroundColor={"#c7c7c7"}
                                    foregroundColor={"#c7c7c7"}
                                  />
                                </li>
                                <li>
                                  <Facebook
                                    backgroundColor={"#c7c7c7"}
                                    foregroundColor={"#c7c7c7"}
                                  />
                                </li>
                              </ul>
                            ) : this.state.myBrandsList.length > 0 ? (
                              <ul className="grid-tab">
                                {this.state.myBrandsList.map((item, index) => {
                                  return (
                                    <li key={index}>
                                      <div className="gt-lhs">
                                        <figure>
                                          <img src={vouclogo} />{" "}
                                        </figure>
                                        <h3>
                                          {item.product_alias !== "" &&
                                          item.product_alias !== null
                                            ? stripslashes(item.product_alias)
                                            : stripslashes(item.product_name)}
                                        </h3>
                                        <p>
                                          Offer Valid -{" "}
                                          {item.product_voucher_expiry_date}
                                        </p>
                                        <div className="copy-url">
                                          <div className="input-group">
                                            <input
                                              type="text"
                                              className="form-control"
                                              defaultValue={
                                                forntEndUrl +
                                                "buy-vouchers/" +
                                                item.product_slug +
                                                "/" +
                                                cookie.load("influencerID")
                                              }
                                            />
                                            <span
                                              className="input-group-text cursor-pointer"
                                              onClick={this.referLink.bind(
                                                this,
                                                item.product_slug
                                              )}
                                              title="Copy URL"
                                            >
                                              <i className="mdi mdi-content-copy"></i>
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="gt-rhs">
                                        <figure>
                                          <img src={vouc} />{" "}
                                        </figure>
                                        Buy For
                                        <br />
                                        <strong>
                                          {showPriceValue(item.product_price)}
                                        </strong>
                                      </div>
                                      <div className="gt-btm">
                                        <ul>
                                          <li>
                                            Clicks :{" "}
                                            <strong>
                                              {item.campaign_click_count || 0}
                                            </strong>
                                          </li>
                                          <li>
                                            Signed Up :{" "}
                                            <strong>
                                              {item.campaign_signed_up || 0}
                                            </strong>
                                          </li>
                                          <li>
                                            Earned :{" "}
                                            <strong>
                                              {showPriceValue(
                                                item.campaign_earned
                                              )}
                                            </strong>
                                          </li>
                                        </ul>
                                      </div>
                                    </li>
                                  );
                                })}
                              </ul>
                            ) : (
                              <ul className="grid-tab no-record">
                                <li>No Recod Found</li>
                              </ul>
                            )}
                          </div>
                          <Pagenation
                            params={{
                              totalRecords: this.state.mytotalRecords,
                              totalPage: this.state.mytotalPage,
                              currentPage: this.state.mycurrentPage,
                            }}
                            sateValChange={this.sateValChange1}
                          />
                        </div>
                      )}
                      {this.state.activeTap === "allbrands" && (
                        <div>
                          <div className="campaign-tab-content-inner tab2">
                            {this.state.categoryList.length > 0 && (
                              <div className="sort-hidden">
                                <ul>
                                  <li
                                    className={
                                      this.state.selectedCategory === "all"
                                        ? "active"
                                        : ""
                                    }
                                  >
                                    <a
                                      href={void 0}
                                      onClick={this.filtterCategory.bind(
                                        this,
                                        "all"
                                      )}
                                    >
                                      <figure>
                                        <img src={c1} alt="All" />{" "}
                                      </figure>
                                      <figcaption>All</figcaption>
                                    </a>
                                  </li>
                                  {this.state.categoryList.map(
                                    (item, index) => {
                                      return (
                                        <li
                                          className={
                                            this.state.selectedCategory ===
                                            item.cate_id
                                              ? "active"
                                              : ""
                                          }
                                          key={index}
                                        >
                                          <a
                                            href={void 0}
                                            onClick={this.filtterCategory.bind(
                                              this,
                                              item.cate_id
                                            )}
                                          >
                                            <figure>
                                              <img
                                                src={
                                                  item.cate_image !== "" &&
                                                  item.cate_image !== null
                                                    ? item.cate_image
                                                    : c1
                                                }
                                                alt={item.cate_name}
                                              />{" "}
                                            </figure>
                                            <figcaption>
                                              {item.cate_name}
                                            </figcaption>
                                          </a>
                                        </li>
                                      );
                                    }
                                  )}

                                  {/*  <li>
                                    <a href="#">
                                      <figure>
                                        <img src={c2} />{" "}
                                      </figure>
                                      <figcaption>Auto</figcaption>
                                    </a>
                                  </li>
                                  <li>
                                    <a href="#">
                                      <figure>
                                        <img src={c3} />{" "}
                                      </figure>
                                      <figcaption>Home & Garden</figcaption>
                                    </a>
                                  </li>
                                  <li>
                                    <a href="#">
                                      <figure>
                                        <img src={c4} />{" "}
                                      </figure>
                                      <figcaption>Real Estate</figcaption>
                                    </a>
                                  </li>
                                  <li>
                                    <a href="#">
                                      <figure>
                                        <img src={c5} />{" "}
                                      </figure>
                                      <figcaption>Health & Beauty</figcaption>
                                    </a>
                                  </li>
                                  <li>
                                    <a href="#">
                                      <figure>
                                        <img src={c6} />{" "}
                                      </figure>
                                      <figcaption>Legal & Finance</figcaption>
                                    </a>
                                  </li>
                                  <li>
                                    <a href="#">
                                      <figure>
                                        <img src={c7} />{" "}
                                      </figure>
                                      <figcaption>Food & Dining</figcaption>
                                    </a>
                                  </li>
                                  <li>
                                    <a href="#">
                                      <figure>
                                        <img src={c8} />{" "}
                                      </figure>
                                      <figcaption>B2B</figcaption>
                                    </a>
                                  </li>
                                  <li>
                                    <a href="#">
                                      <figure>
                                        <img src={c9} />{" "}
                                      </figure>
                                      <figcaption>community</figcaption>
                                    </a>
                                  </li>
                                  <li>
                                    <a href="#">
                                      <figure>
                                        <img src={c10} />{" "}
                                      </figure>
                                      <figcaption>Education</figcaption>
                                    </a>
                                  </li> */}
                                </ul>
                              </div>
                            )}

                            {this.state.loading === true ? (
                              <ul className="grid-tab gt-viewmore">
                                <li>
                                  <Facebook
                                    backgroundColor={"#c7c7c7"}
                                    foregroundColor={"#c7c7c7"}
                                  />
                                </li>
                                <li>
                                  <Facebook
                                    backgroundColor={"#c7c7c7"}
                                    foregroundColor={"#c7c7c7"}
                                  />
                                </li>
                                <li>
                                  <Facebook
                                    backgroundColor={"#c7c7c7"}
                                    foregroundColor={"#c7c7c7"}
                                  />
                                </li>
                              </ul>
                            ) : this.state.listdata.length > 0 ? (
                              <ul className="grid-tab gt-viewmore">
                                {this.state.listdata.map((item, index) => {
                                  return (
                                    <li key={index}>
                                      <div className="gt-lhs">
                                        {item.product_influencer_tag !== "" &&
                                          item.product_influencer_tag !==
                                            null && (
                                            <div className="tag">
                                              {item.product_influencer_tag}
                                            </div>
                                          )}
                                        <figure>
                                          <img src={vouclogo} />{" "}
                                        </figure>
                                        <h3>
                                          {item.product_alias !== "" &&
                                          item.product_alias !== null
                                            ? stripslashes(item.product_alias)
                                            : stripslashes(item.product_name)}
                                        </h3>
                                        <p>
                                          Offer Valid -{" "}
                                          {item.product_voucher_expiry_date}
                                        </p>
                                        <div className="gt-view">
                                          <a
                                            href={void 0}
                                            onClick={this.selectVoucher.bind(
                                              this,
                                              item
                                            )}
                                          >
                                            View More
                                          </a>
                                        </div>
                                      </div>
                                      <div className="gt-rhs">
                                        <figure>
                                          <img src={vouc} />{" "}
                                        </figure>
                                        Buy For
                                        <br />
                                        <strong>
                                          {showPriceValue(item.product_price)}
                                        </strong>
                                      </div>
                                    </li>
                                  );
                                })}
                              </ul>
                            ) : (
                              "No Record Found"
                            )}
                          </div>
                          {this.state.totalPage > 1 && (
                            <Pagenation
                              params={{
                                totalRecords: this.state.totalRecords,
                                totalPage: this.state.totalPage,
                                currentPage: this.state.currentPage,
                              }}
                              sateValChange={this.sateValChange}
                            />
                          )}
                        </div>
                      )}

                      {this.state.activeTap === "featured" && (
                        <div>
                          <div className="campaign-tab-content-inner tab2">
                            {this.state.featuredBrandsLoading === true ? (
                              <ul className="grid-tab gt-viewmore">
                                <li>
                                  <Facebook
                                    backgroundColor={"#c7c7c7"}
                                    foregroundColor={"#c7c7c7"}
                                  />
                                </li>
                                <li>
                                  <Facebook
                                    backgroundColor={"#c7c7c7"}
                                    foregroundColor={"#c7c7c7"}
                                  />
                                </li>
                                <li>
                                  <Facebook
                                    backgroundColor={"#c7c7c7"}
                                    foregroundColor={"#c7c7c7"}
                                  />
                                </li>
                              </ul>
                            ) : this.state.featuredBrandsList.length > 0 ? (
                              <ul className="grid-tab gt-viewmore">
                                {this.state.featuredBrandsList.map(
                                  (item, index) => {
                                    return (
                                      <li key={index}>
                                        <div className="gt-lhs">
                                          {item.product_influencer_tag !== "" &&
                                            item.product_influencer_tag !==
                                              null && (
                                              <div className="tag">
                                                {item.product_influencer_tag}
                                              </div>
                                            )}
                                          <figure>
                                            <img src={vouclogo} />{" "}
                                          </figure>
                                          <h3>
                                            {item.product_alias !== "" &&
                                            item.product_alias !== null
                                              ? stripslashes(item.product_alias)
                                              : stripslashes(item.product_name)}
                                          </h3>
                                          <p>
                                            Offer Valid -{" "}
                                            {item.product_voucher_expiry_date}
                                          </p>
                                          <div className="gt-view">
                                            <a
                                              href={void 0}
                                              onClick={this.selectVoucher.bind(
                                                this,
                                                item
                                              )}
                                            >
                                              View More
                                            </a>
                                          </div>
                                        </div>
                                        <div className="gt-rhs">
                                          <figure>
                                            <img src={vouc} />{" "}
                                          </figure>
                                          Buy For
                                          <br />
                                          <strong>
                                            {showPriceValue(item.product_price)}
                                          </strong>
                                        </div>
                                      </li>
                                    );
                                  }
                                )}
                              </ul>
                            ) : (
                              "No Record Found"
                            )}
                          </div>
                          {this.state.totalPage > 1 && (
                            <Pagenation
                              params={{
                                totalRecords: this.state.featuredtotalRecords,
                                totalPage: this.state.featuredtotalPage,
                                currentPage: this.state.featuredcurrentPage,
                              }}
                              sateValChange={this.sateValChange3}
                            />
                          )}
                        </div>
                      )}

                      {this.state.activeTap === "applied" && (
                        <div>
                          <div className="campaign-tab-content-inner tab1">
                            {this.state.myCampaignLoading === true ? (
                              <ul className="grid-tab gt-viewmore">
                                <li>
                                  <Facebook
                                    backgroundColor={"#c7c7c7"}
                                    foregroundColor={"#c7c7c7"}
                                  />
                                </li>
                                <li>
                                  <Facebook
                                    backgroundColor={"#c7c7c7"}
                                    foregroundColor={"#c7c7c7"}
                                  />
                                </li>
                                <li>
                                  <Facebook
                                    backgroundColor={"#c7c7c7"}
                                    foregroundColor={"#c7c7c7"}
                                  />
                                </li>
                              </ul>
                            ) : this.state.appliedBrandsList.length > 0 ? (
                              <ul className="grid-tab">
                                {this.state.appliedBrandsList.map(
                                  (item, index) => {
                                    return (
                                      <li key={index}>
                                        <div className="gt-lhs">
                                          <figure>
                                            <img src={vouclogo} />{" "}
                                          </figure>
                                          <h3>
                                            {item.product_alias !== "" &&
                                            item.product_alias !== null
                                              ? stripslashes(item.product_alias)
                                              : stripslashes(item.product_name)}
                                          </h3>
                                          <p>
                                            Offer Valid -{" "}
                                            {item.product_voucher_expiry_date}
                                          </p>
                                          <div className="copy-url">
                                            <div className="input-group">
                                              <input
                                                type="text"
                                                className="form-control"
                                                defaultValue={
                                                  forntEndUrl +
                                                  "buy-vouchers/" +
                                                  item.product_slug +
                                                  "/" +
                                                  cookie.load("influencerID")
                                                }
                                              />
                                              <span
                                                className="input-group-text cursor-pointer"
                                                onClick={this.referLink.bind(
                                                  this,
                                                  item.product_slug
                                                )}
                                                title="Copy URL"
                                              >
                                                <i className="mdi mdi-content-copy"></i>
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="gt-rhs">
                                          <figure>
                                            <img src={vouc} />{" "}
                                          </figure>
                                          Buy For
                                          <br />
                                          <strong>
                                            {showPriceValue(item.product_price)}
                                          </strong>
                                        </div>
                                      </li>
                                    );
                                  }
                                )}
                              </ul>
                            ) : (
                              <ul className="grid-tab no-record">
                                <li>No Recod Found</li>
                              </ul>
                            )}
                          </div>
                          <Pagenation
                            params={{
                              totalRecords: this.state.appliedtotalRecords,
                              totalPage: this.state.appliedtotalPage,
                              currentPage: this.state.appliedcurrentPage,
                            }}
                            sateValChange={this.sateValChange2}
                          />
                        </div>
                      )}

                      <div
                        id="confirm_popup"
                        className="white-popup mfp-hide popup_sec confirm_popup"
                      >
                        <div className="how-comn-wrk-popup">
                          <h2>How Commission Work</h2>
                          <div className="commission-content">
                            <h4>How to earn your commission</h4>
                            <ul>
                              <li>
                                Click apply to refer button and copy sharing
                                link
                              </li>
                              <li>Share with your followers</li>
                              <li>
                                Earn commission for each completed order from
                                your referrals{" "}
                              </li>
                            </ul>

                            <h4>How to collected your commission</h4>
                            <ul>
                              <li>Update your bank details in your profile.</li>
                              <li>Accept all the terms and conditions</li>
                              <li>
                                You can receive your payout within 7 business
                                days
                              </li>
                            </ul>
                          </div>
                          <div className="chkbox-comm">
                            <div className="form-check">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                value=""
                                id="defaultCheck3"
                              />
                              <label
                                className="form-check-label"
                                htmlFor="defaultCheck3"
                              >
                                {" "}
                                I confirm I have read and accept the{" "}
                              </label>
                            </div>{" "}
                            <a href="#">Terms & Conditions</a>,{" "}
                            <a href="#">Privacy Policy</a> and the
                            <a href="#"> PDPA consent.</a>
                          </div>
                          <div className="chkbox-continue">
                            <a
                              href={void 0}
                              className="button btn continuebuyVoucher"
                              onClick={this.contBuyVoucher.bind(this)}
                            >
                              Continue
                            </a>
                          </div>
                        </div>
                      </div>

                      <div
                        id="continue_popup"
                        className="white-popup mfp-hide popup_sec confirm_popup"
                      >
                        <div className="spread">
                          <div className="abstract">
                            <div className="abstract-desc">
                              <div className="abd-title-lhs">
                                <div className="abd-title">
                                  {this.state.selectedVoucher?.outlet_image && (
                                    <figure>
                                      <img
                                        src={
                                          this.state.selectedVoucher
                                            ?.outlet_image
                                        }
                                      />
                                    </figure>
                                  )}
                                  <h3>
                                    {this.state.selectedVoucher?.company_name}
                                  </h3>
                                </div>
                              </div>
                              <div className="abd-title-rhs">
                                <a href="#" className="button ghost-button">
                                  Available Locations
                                </a>
                                <ul>
                                  <li>
                                    {" "}
                                    <a href="#">
                                      <img src={globe} /> www.desigual.com
                                    </a>{" "}
                                  </li>
                                  <li>
                                    {" "}
                                    <a href="#">
                                      <img src={calla} /> +65 1345 7895
                                    </a>{" "}
                                  </li>
                                </ul>
                              </div>
                            </div>
                            {this.state.selectedVoucher?.outlet_informations &&
                              this.state.selectedVoucher
                                ?.outlet_informations !== null &&
                              Parser(
                                this.state.selectedVoucher?.outlet_informations
                              )}
                          </div>
                          <div className="summary">
                            <div className="orange-banner">
                              <img className="" src={voucherbg} />
                            </div>
                            <div className="credit-list-full">
                              <ul>
                                <li>
                                  <div className="list-parent-one">
                                    <figure>
                                      {" "}
                                      <img src={walletlight} />{" "}
                                    </figure>
                                    <div className="buy-credit-txt-ful">
                                      <h2>
                                        {this.state.selectedVoucher
                                          ?.product_alias ||
                                          this.state.selectedVoucher
                                            ?.outlet_name}
                                      </h2>
                                      <span>
                                        Offer Valid -{" "}
                                        {
                                          this.state.selectedVoucher
                                            ?.product_voucher_expiry_date
                                        }{" "}
                                        Only redeemable once
                                      </span>
                                    </div>
                                    <div className="credit-offer-big">
                                      <span>Buy For </span>
                                      <h2>
                                        {showPriceValue(
                                          this.state.selectedVoucher
                                            ?.product_price
                                        )}
                                      </h2>
                                    </div>
                                    <img
                                      className="last-img-big"
                                      src={orange}
                                    />{" "}
                                  </div>
                                </li>
                              </ul>
                            </div>
                            <div className="pc-content">
                              <h3>
                                {showPercentage(
                                  this.state.selectedVoucher
                                    ?.product_influencer_commission || ""
                                )}
                                % Commission{" "}
                              </h3>
                              <span>for each referral </span>
                              <br />
                              <br />
                              <h4>How to earn your commission</h4>
                              <ul>
                                <li>
                                  Click apply to refer button and copy sharing
                                  link
                                </li>
                                <li>Share with your followers</li>
                                <li>
                                  Earn commission for each completed order from
                                  your referrals{" "}
                                </li>
                              </ul>
                            </div>
                            <div className="voucher-buy-now-two">
                              <a
                                href={void 0}
                                className="button orng-btn"
                                id="applyRefer"
                              >
                                Apply To Refer
                              </a>
                              {this.state.selectedVoucher?.product_buy_refer ===
                                "1" && (
                                <a
                                  href={
                                    forntEndUrl +
                                    "buy-vouchers/" +
                                    this.state.selectedVoucher?.product_slug +
                                    "/" +
                                    cookie.load("influencerID") +
                                    "/" +
                                    cookie.load("accessToken")
                                  }
                                  className="button orng-btn"
                                  target="_blank"
                                >
                                  Buy Now
                                </a>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <Footer />
            </div>
          </div>
        </div>

        <div className="layout-overlay layout-menu-toggle"></div>

        <div className="drag-target"></div>
      </div>
    );
  }
}

const mapStateTopProps = (state) => {
  var listdata = Array();
  var listdataStatus = "";
  var totalPages = 0;
  var totalRecords = 0;
  if (Object.keys(state.listdata).length > 0) {
    listdataStatus = state.listdata[0].status;
    if (state.listdata[0].status === "ok") {
      listdata = state.listdata[0].result;
      totalPages = state.listdata[0].totalPages;
      totalRecords = state.listdata[0].totalRecords;
    }
  }
  return {
    listdata: listdata,
    totalPages: totalPages,
    totalRecords: totalRecords,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getListData: (datas) => {
      dispatch({ type: GET_LISTDATA, datas });
    },
  };
};

export default connect(mapStateTopProps, mapDispatchToProps)(List);
