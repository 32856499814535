/* eslint-disable */
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import cookie from "react-cookies";
import axios from "axios";
import Select from "react-select";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  apiUrl,
  adminlimit,
  headerConfig,
  forntEndUrl,
} from "../Helpers/Config";
import { GET_LISTDATA } from "../../actions";
import { showStatus, encodeValue, removeItem } from "../Helpers/SettingHelper";
import Header from "../Layout/Header";
import Topmenu from "../Layout/Topmenu";
import Footer from "../Layout/Footer";
import Pagenation from "../Layout/Pagenation";
var module = "clientpanel/paintbasedproducts/";
var moduleName = "Vouchers";
class List extends Component {
  constructor(props) {
    super(props);
    var influencerID = cookie.load("influencerID");
    this.state = {
      influencerID: influencerID,
      path: this.props.match.path,
      totalRecords: 0,
      totalPage: 0,
      currentPage: 1,
      listdata: [],
      loading: true,
      name: "",
      status: "",
      companyID: "",
      producttype: "",
      companyList: [],
    };
    this.handleChangeText = this.handleChangeText.bind(this);
  }
  componentDidMount() {
    this.loadList(1);
    this.loadcompany();
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.listdata !== this.state.listdata) {
      this.setState({
        listdata: nextProps.listdata,
        loading: false,
        totalRecords: nextProps.totalRecords,
        totalPage: nextProps.totalPages,
      });
    }
  }
  loadcompany() {
    var urlShringTxt =
      apiUrl +
      "clientpanel/influencer/clientlist?influencerID=" +
      this.state.influencerID;
    axios.get(urlShringTxt, headerConfig).then((res) => {
      if (res.data.status === "ok") {
        this.setState({ companyList: res.data.result });
      }
    });
  }

  sateValChange = (field, value) => {
    if (field === "page") {
      this.setState(
        {
          loading: true,
          currentPage: value,
        },
        function () {
          this.loadList(value);
        }
      );
    }
  };
  removeItem(deleteID) {
    var params = { delete_id: deleteID, influencerID: this.state.influencerID };
    var delurl = module + "delete";
    removeItem(params, delurl, "client");
  }
  handleChangeText(event) {
    var name = event.target.name;
    var value = event.target.value;
    this.setState({ [name]: value });
  }
  handleSelectChange(name, value) {
    this.setState({ [name]: value }, function () {});
  }

  searchList() {
    this.setState({ loading: true }, function () {
      this.loadList(1);
    });
  }
  loadList(offset) {
    var addParams = "";
    if (this.state.name !== "") {
      addParams += "&name=" + this.state.name;
    }
    if (
      this.state.companyID !== null &&
      Object.keys(this.state.companyID).length > 0
    ) {
      addParams += "&companyID=" + encodeValue(this.state.companyID.value);
    }
    if (
      this.state.producttype !== null &&
      Object.keys(this.state.producttype).length > 0
    ) {
      addParams += "&producttype=" + this.state.producttype.value;
    }
    if (
      this.state.status !== null &&
      Object.keys(this.state.status).length > 0
    ) {
      addParams += "&status=" + this.state.status.value;
    }

    var params = {
      params:
        "limit=" +
        adminlimit +
        "&offset=" +
        offset +
        "&influencerID=" +
        this.state.influencerID +
        addParams,
      url: apiUrl + module + "influencerlist",
      authType: "client",
    };
    this.props.getListData(params);
  }
  resetSearch() {
    this.setState(
      {
        loading: true,
        name: "",
        companyID: "",
        producttype: "",
        status: "",
      },
      function () {
        this.loadList(1);
      }
    );
  }
  referLink(itemSlug) {
    toast.success("Link Copied Success", { autoClose: 1000 });
    navigator.clipboard.writeText(
      forntEndUrl +
        "buy-vouchers/" +
        itemSlug +
        "/" +
        cookie.load("influencerID")
    );
  }

  render() {
    return (
      <div className="layout-wrapper layout-content-navbar">
        <div className="layout-container">
          <Header {...this.props} currentPage={"vouchers"} />
          <div className="layout-page">
            <Topmenu />
            <ToastContainer />
            <div className="content-wrapper">
              <div className="container-xxl flex-grow-1 container-p-y">
                <div className="row mb-3">
                  <div className="col-lg-10 col-md-6">
                    <h4 className="fw-bold">{moduleName}</h4>
                  </div>
                </div>
                <div className="row mb-4 pro-filter-row">
                  <div className="col-md-3 pro-filter-text">
                    <div className="form-floating form-floating-outline mb-4">
                      <input
                        type="text"
                        className="form-control"
                        name="name"
                        onChange={this.handleChangeText}
                        value={this.state.name}
                      />
                      <label htmlFor="name">Product Name</label>
                    </div>
                  </div>

                  <div className="col-md-3 pro-filter-outlet">
                    <div className="form-floating form-floating-outline custm-select-box filter-select mb-4">
                      <Select
                        value={this.state.companyID}
                        onChange={this.handleSelectChange.bind(
                          this,
                          "companyID"
                        )}
                        placeholder="Select Company"
                        isClearable={true}
                        options={this.state.companyList}
                      />
                      <label className="select-box-label">Company</label>
                    </div>
                  </div>

                  <div className="col-md-1 pro-filter-sbtbtn ">
                    <button
                      type="button"
                      className="btn btn-primary me-sm-3 me-1 mt-2 waves-effect waves-light"
                      onClick={this.searchList.bind(this)}
                    >
                      Search
                    </button>
                  </div>

                  <div className="col-md-1 pro-filter-refbtn">
                    <button
                      type="reset"
                      className="btn btn-label-secondary waves-effect mt-2"
                      onClick={this.resetSearch.bind(this)}
                    >
                      Reset
                    </button>
                  </div>
                </div>

                <div className="card">
                  <div className="table-responsive text-nowrap p-1 mt-4">
                    <table className="table">
                      <thead>
                        <tr>
                          <th>Image</th>
                          <th>Product Name</th>
                          <th>Company Name</th>
                          <th>Voucher Type</th>
                          <th>SKU</th>
                          <th>Price</th>
                          <th>Commission</th>
                          <th>Refer</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.loading === true ? (
                          <tr>
                            <td colSpan={7} align="center">
                              <div
                                className="spinner-border spinner-border-lg text-primary"
                                role="status"
                              >
                                <span className="visually-hidden">
                                  Loading...
                                </span>
                              </div>
                            </td>
                          </tr>
                        ) : this.state.listdata.length > 0 ? (
                          this.state.listdata.map((item, index) => {
                            return (
                              <tr key={index}>
                                <td>
                                  {item.product_thumbnail !== "" &&
                                  item.product_thumbnail !== null ? (
                                    <img
                                      src={item.product_thumbnail}
                                      alt={item.product_name}
                                      width={100}
                                      height={100}
                                    />
                                  ) : (
                                    ""
                                  )}
                                </td>
                                <td>
                                  <strong>
                                    {item.product_alias !== "" &&
                                    item.product_alias !== null
                                      ? item.product_alias
                                      : item.product_name}
                                  </strong>
                                </td>
                                <td>{item.company_name}</td>
                                <td>
                                  {item.product_voucher === "C"
                                    ? "Credits"
                                    : "Product"}
                                </td>
                                <td>{item.product_sku}</td>
                                <td>{item.product_price}</td>
                                <td>{item.product_influencer_commission}%</td>
                                <td>
                                  {item.product_buy_refer === "1" ? (
                                    <button
                                      type="button"
                                      className="btn btn-primary me-sm-3 me-1 waves-effect waves-light"
                                      onClick={this.searchList.bind(this)}
                                    >
                                      Refer & Buy
                                    </button>
                                  ) : (
                                    <button
                                      type="button"
                                      className="btn btn-primary me-sm-3 me-1 waves-effect waves-light"
                                      onClick={this.referLink.bind(
                                        this,
                                        item.product_slug
                                      )}
                                    >
                                      Refer
                                    </button>
                                  )}
                                </td>
                              </tr>
                            );
                          })
                        ) : (
                          <tr>
                            <td className="text-center" colSpan={8}>
                              No Outlet Found
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                  <Pagenation
                    params={{
                      totalRecords: this.state.totalRecords,
                      totalPage: this.state.totalPage,
                      currentPage: this.state.currentPage,
                    }}
                    sateValChange={this.sateValChange}
                  />
                </div>
              </div>

              <Footer />
            </div>
          </div>
        </div>

        <div className="layout-overlay layout-menu-toggle"></div>

        <div className="drag-target"></div>
      </div>
    );
  }
}

const mapStateTopProps = (state) => {
  var listdata = Array();
  var listdataStatus = "";
  var totalPages = 0;
  var totalRecords = 0;
  if (Object.keys(state.listdata).length > 0) {
    listdataStatus = state.listdata[0].status;
    if (state.listdata[0].status === "ok") {
      listdata = state.listdata[0].result;
      totalPages = state.listdata[0].totalPages;
      totalRecords = state.listdata[0].totalRecords;
    }
  }
  return {
    listdata: listdata,
    totalPages: totalPages,
    totalRecords: totalRecords,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getListData: (datas) => {
      dispatch({ type: GET_LISTDATA, datas });
    },
  };
};

export default connect(mapStateTopProps, mapDispatchToProps)(List);
